<template>
	<div class="notice">
		<div class="header">
			<div @click="close" class="titlename">{{date.columnName}}></div>
			<div class="current">正文</div>
		</div>

		<div class="title" v-html="date.title"></div>
		
		<div class="horizontalline"></div>

		<div class="articledetails">
			<div class="name1"><span>来源：</span>{{date.source}}</div>
			<div class="name2"><span>时间：</span>{{date.publishTime}}</div>
		</div>
		<div v-html="date.content" class="content"></div>
		<!-- <div v-if="date.imgUrl" style="margin-bottom: 20PX">
			<img :src="date.imgUrl" alt="" style="width: 1200PX;">
		</div> -->
		<div v-if="date.fileUrlList && date.fileUrlList.length > 0">
			<ul>
				<li v-for="(item,index) in date.fileUrlList" :key="index+'one'" style="margin-bottom: 20PX">
					<div>
						<img src="/images/fujian.png" alt="" style="width: 20PX">
						<a :href="item.url" style="color: #2d52a0">{{item.name}}</a>
					</div>
				</li>
			</ul>
		</div>

		<div class="button">
			<div class="button_name" @click="getupper">【 {{ upper }} 】</div>
			<div class="button_name" @click="getbelow">【 {{ below }} 】</div>
			<div class="button_name" @click="close">【 关闭 】</div>
		</div>

		<div class="left_window">
			<h2 style="padding: 5PX 10PX;">相关信息</h2>
			<ul style="font-size: 14PX; line-height: 28PX;">
				<li class="spantodate1" v-for="(item,index) in recomList" :key="index" @click="jump(item.id)">
					·{{item.title}}</li>
			</ul>
		</div>

	</div>
</template>
<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	export default {
		name: 'articledetails',
		components: {},
		data() {
			return {
				id: '',
				date: {},
				recomList: [],
				upper: '上一篇',
				upperbur: false,
				below: '下一篇',
				belowbur: false,
				pattern:'<br>'
			}
		},
		created() {
			this.id = this.$route.query.id
			this.getArticleData(this.id)
		},
		methods: {
			getArticleData(id) {
				let data = {
					id: id,
				}
				this.$api.getArticleData(data).then(res => {
					res.data.data.fileUrlList.forEach(item=>{
						if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
							if (!item.url.startsWith('https')) {
								item.url = 'https' + item.url.slice(4);
							}
						}
					})
					this.date = res.data.data
					console.log(this.date.fileUrlList)
					this.recommend(res.data.data.columnId)
					
					document.title = this.date.title.replace(/<br>/g, ' ') + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
					
					if (res.data.data.prevArticle == null || res.data.data.prevArticle == undefined || res.data.data.prevArticle == '') {
						this.upper = '结束了',
							this.upperbur = true
					}else{
						this.upper = '上一篇',
						this.upperbur = false
					}
					
					if (res.data.data.nextArticle == null || res.data.data.nextArticle == undefined || res.data.data.nextArticle == '') {
						this.below = '结束了',
							this.belowbur = true
					}else{
						this.below = '下一篇',
						this.belowbur = false
					}
				})
			},
			recommend(id) {
				this.$api.recommend(id).then(res => {
					this.recomList = res.data.data
					
					this.recomList.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			close() {
				window.close()
			},
			getupper() {
				if (this.date.prevArticle == null || this.date.prevArticle == undefined || this.date.prevArticle == '')
					return
				this.getArticleData(this.date.prevArticle.id)
				window.scrollTo(0, 0)
			},
			getbelow() {
				if (this.date.nextArticle == null || this.date.nextArticle == undefined || this.date.nextArticle == '')
					return
				this.getArticleData(this.date.nextArticle.id)
				window.scrollTo(0, 0)
			},
			geturl(url){
				if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
					if (!url.startsWith('https')) {
						url = 'https' + url.slice(4);
					}
					window.open(url);
				} else {
					window.open(url);
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-bottom: 23PX;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
	}

	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
	}

	.articledetails {
		display: flex;
		width: 1200PX;
		margin: 0 auto 36PX;
		font-size: 16PX;
		justify-content: center;
	}

	.name1 span {
		color: #9A9A9A;
		font-size: 15PX;
	}

	.name2 span {
		color: #9A9A9A;
		font-size: 15PX;
	}

	.name2 {
		margin-left: 15PX;
	}

	.title {
		text-align: center;
		// height: 100PX;
		/* line-height: 100PX; */
		font-size: 24PX;
		color: #343434;
		padding: 10PX 0;
	}

	.content {
		margin-bottom: 20PX;
	}

	.left_window {
		border: 1PX solid #D4D4D4;
		margin-bottom: 20PX;
		background: none repeat scroll 0 0 #FFFFFF;
		margin-top: 40PX;
	}

	.left_window h2 {
		line-height: 25PX;
		font-weight: 800;
		color: #111111;
		font-size: 15PX;
		padding: 0PX 10PX;
		background-color: #F5F5F5;
	}

	.left_window ul {
		font-size: 13PX;
		line-height: 25PX;
		margin: 10PX;
	}

	li {
		list-style: none;
		cursor: pointer;
	}

	.button {
		width: 1200PX;
		margin: 40PX auto 0;
		display: flex;
		justify-content: flex-end;
	}

	.button_name {
		cursor: pointer;
	}
	
	.titlename:hover{
		color: #0066FF;
	}
</style>